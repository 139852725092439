const CommonUtility = {
  getText(val, items) {
    let result = items.find((obj) => {
      return obj.value == val;
    });
    if (result) {
      return result.text;
    }
    return val;
  },
  getNowDateTime() {
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    return formattedDate;
  },
  getNowDate() {
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    return formattedDate;
  },
  // get date format yyyy-mm-dd
  getDateFormat(date) {
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return formattedDate;
  },
  getSortParas(sortBy, sortDesc) {
    const sortArray = [];
    let item = "";
    for (let i = 0; i < sortBy.length; i += 1) {
      const sortStr = sortBy[i].replace(".", "__");
      if (sortDesc[i]) {
        item = sortStr;
      } else {
        item = `-${sortStr}`;
      }
      sortArray.push(item);
    }
    console.log(sortArray);

    return sortArray.join();
  },
  getWeekend() {
    const today = new Date(); // Get today's date
    const year = today.getFullYear(); // Get the current year
    const month = today.getMonth(); // Get the current month (zero-indexed)

    const allDates = []; // An array to store all the dates for Saturday and Sunday in the current month

    // Loop through all the days in the current month
    for (let i = 1; i <= new Date(year, month + 1, 0).getDate(); i++) {
      const date = new Date(year, month, i); // Create a new date object for the current day
      const dayOfWeek = date.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, etc.)

      if (dayOfWeek === 6 || dayOfWeek === 0) { // If the day is a Saturday or Sunday
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        allDates.push(formattedDate); // Add the date to the array
      }
    }
    return allDates;
  },
  getWeekendDays(start, end) {
    var weekendDays = [];

    // Loop through each day between start and end
    for (var date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
      // Check if the day is a weekend (Saturday or Sunday)
      if (date.getDay() === 6 || date.getDay() === 0) {
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        weekendDays.push(formattedDate); // Add the weekend day to the array
      }
    }

    return weekendDays;
  },
  convertChineseFractionToFloat(chineseFraction) {
    const regex = /(\d*)分之(\d+)/;
    const match = chineseFraction.match(regex);
    if (match) {
      const denominator = parseInt(match[1], 10);
      const numerator = parseInt(match[2], 10);
      if (denominator > 0 && numerator >= 0) {
        return numerator / denominator;
      }
    }

    // If the input string is not in the expected format, return NaN
    return "";
  },
  extractNumberFromSquareMeters(text) {
    const regex = /(\d+(\.\d+)?)/;
    const match = text.match(regex);

    if (match) {
      return parseFloat(match[0]);
    }

    // If the input string does not contain a number, return NaN
    return "";
  },
  getCDRInfo(lbkey) {
    let city = null;
    let district = null;
    let region = null;
    let no = null;

    city = lbkey.slice(0, 1);
    district = lbkey.slice(2, 4);
    region = lbkey.slice(5, 9);
    no = lbkey.slice(10);

    return [city, district, region, no];
  },
  padNumberWithZeros(number, desiredLength) {
    if (number == null) {
      return null;
    }
    // 將數字轉換成字串
    let numStr = number.toString();

    // 計算需要補幾個零
    let zerosToAdd = desiredLength - numStr.length;

    // 如果需要補零，則補零
    if (zerosToAdd > 0) {
      numStr = "0".repeat(zerosToAdd) + numStr;
    }

    return numStr;
  },
  validateKey(key) {
    const LKEY_VALIDATION = /^[A-Z]_[0-9]{2}_[0-9]{4}_[0-9]{4}-[0-9]{4}$/;
    const BKEY_VALIDATION = /^[A-Z]_[0-9]{2}_[0-9]{4}_[0-9]{5}-[0-9]{3}$/;
    return LKEY_VALIDATION.test(key) || BKEY_VALIDATION.test(key);
  },
  squareMeterToPing(squareMeter) {
    // 1 平方公尺 = 0.3025 坪
    if (squareMeter == null) {
      return null;
    }
    const conversionRate = 0.3025;
    const ping = squareMeter * conversionRate;
    return ping;
  },
  formatChineseYear(val) {
    let year = parseInt(val) - 1911;
    return `民國${year}年`;
  },
  displayChineseYear(val) {
    if (val != null) {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(val)) {
        console.log("日期格式不合法（yyyy-mm-dd）");
        return val; // 返回 null 表示日期格式不合法
      }
      const year = parseInt(val.substring(0, 4));
      const month = parseInt(val.substring(5, 7));
      const day = parseInt(val.substring(8, 10));

      return this.formatChineseYear(year) + `${month}月${day}日`;
    }
    return val;
  },
  getCDR(info) {
    const city = info.city;
    const district = info.district;
    const region = info.region;
    if (city && district && region) {
      return `${city}_${district}_${region}`;
    }
    return null;
  },

  getLandKey(info) {
    // const city = info.city;
    // const district = info.district;
    // const region = info.region;
    const cdr = this.getCDR(info);
    if (cdr == null) {
      return null;
    }
    const mno = this.padNumberWithZeros(info.mno, 4);
    let sno = this.padNumberWithZeros(info.sno, 4);
    if (mno != null && sno == null) {
      sno = "0000";
    }

    // console.log("get land key", city, district, region, mno, sno);
    if (mno && sno) {
      return `${cdr}_${mno}-${sno}`;
    }

    return null;
  },
  coverDisplay(val) {
    if (val == "floors") {
      return "總樓層";
    } else if (val == "basement") {
      return "地下樓數"
    } else if (val == "floor") {
      return "樓層";
    } else if (val == "living_rooms") {
      return "客廳";
    } else if (val == "bathrooms") {
      return "衛浴";
    } else if (val == "rooms") {
      return "房間數";
    }
    return val;
  },
  parseError(error) {
    let msg = "";
    console.log('error code:', error.code)
    console.log(typeof error)
    if (typeof error === "string") {
      if (error == "The Username already exists in the system.") {
        msg += "使用者名稱已存在\n";
      } else if (error == "The Email already exists in the system.") {
        msg += "Email已被其他帳戶使用\n";
      } else if (error == "serial number is duplicated") {
        msg += "序號錯誤 請稍後重試\n";
      } else if (error == "Method not authorized for this user") {
        msg += "使用者權限不足\n";
      }
      else {
        msg = error;
      }
    } else if (typeof error === "object") {
      let errorArray = Object.values(error);
      errorArray.forEach((item) => {
        if (item.loc && item.loc.length == 2) {
          msg += item.loc[1] + "資料缺少或錯誤\n";
        } else if (typeof item === "object") {
          let loc = item['loc'][0]
          msg += this.coverDisplay(loc) + "資料缺少或錯誤 \n";
        }
      });
    }
    return msg;
  },
  parseError2(data) {
    if (
      data.code == "ERR_NETWORK" &&
      data.response &&
      data.response.data == undefined
    ) {
      return "網路異常 新增案件失敗";
    }
    if (data.response && data.response.data && data.response.data.detail) {
      const msg = CommonUtility.parseError(data.response.data.detail);
      if (msg.length > 0) {
        this.loading = {
          dialog: true,
          message: msg,
          show_loading: false,
          show_confirm: true,
        };
        return msg;
      }
    }
    return "系統異常 請稍後再試";
  },
  copyObjectWithoutKey(obj, keyToExclude) {
    const newObj = {};

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && prop !== keyToExclude) {
        newObj[prop] = obj[prop];
      }
    }

    return newObj;
  },
  formatNumber(number) {
    const billion = Math.floor(number / 100000000);
    const million = Math.floor((number % 100000000) / 10000);
    const remainder = number % 10000;

    let formattedNumber = '';

    if (billion > 0) {
      formattedNumber += `${billion}億`;
    }

    if (million > 0) {
      formattedNumber += `${million.toLocaleString()}萬`;
    }

    if (remainder > 0) {
      formattedNumber += remainder.toLocaleString();
    }

    return formattedNumber;
  },
  getTotalArea(item) {
    let total = 0;
    if (item.type == "L") {
      // 获取所有土地的总面积
      item.lands.forEach((land) => {
        const landArea = parseFloat(land.land_area); // 转换为浮点数
        if (!isNaN(landArea)) {
          total += landArea;
        }
      });
    } else {
      item.buildings.forEach((building) => {
        const mainBuilding = parseFloat(building.main_building);
        const subBuilding = parseFloat(building.sub_building);
        const publicSpace = parseFloat(building.public_space);
        const parkingSpace = parseFloat(building.parking_space);

        // 检查每个值是否是有效数字
        if (!isNaN(mainBuilding)) {
          total += mainBuilding;
        }
        if (!isNaN(subBuilding)) {
          total += subBuilding;
        }
        if (!isNaN(publicSpace)) {
          total += publicSpace;
        }
        if (!isNaN(parkingSpace)) {
          total += parkingSpace;
        }
      });
    }
    total = total.toFixed(2);
    return total;
  },
  formatString(inputString) {
    // 使用连字符 "-" 分隔字符串，并过滤掉空字符串
    let parts = inputString.split('-');

    // 将分割后的字符串部分转换为数字
    var numbers = parts.map(function (part) {
      return parseInt(part, 10);
    });

    // 如果第二个数字大于 0，则将两个数字用连字符 "-" 连接，否则只保留第一个数字
    // if (numbers.length >= 2 && numbers[1] > 0) {
    return numbers.join('-');
    // } 
  }
};


export default CommonUtility;
