/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import { tr } from "date-fns/locale";
// const vuetify = new Vuetify({
//   icons: {
//     iconfont: "fa",
//   },
// });

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    dark: false
  },
  rtl: false,
});
