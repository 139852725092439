const draw_infos = [
  {
    title: "案件管理",
    prefix: "C",
    link: "/case-management",
    after: "/case-sales",
  },
  {
    title: "客戶資料",
    prefix: "T",
    link: "/customer-table",
    after: "/dashboards",
  },
  {
    title: "客戶匯入",
    prefix: "I",
    link: "/customer-import",
    after: "/customer-table",
  },
  {
    title: "客戶搜尋",
    prefix: "S",
    link: "/customer-search",
    after: "/dashboards",
  },

  // { title: "新增案件", prefix: "N", link: "/case-draft", after: "/case-sales" },
];

const admin_info = [
  { title: "使用者管理", prefix: "U", link: "/user-list", after: "/admin" },
];

export { draw_infos, admin_info };
