/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import i18n from "./i18n";
import store from "./store";
// CRM
import axios from "axios";
import VueAxios from "vue-axios";
import "./filters/index.js";
import "./global.css"; // Import the global CSS file
import VueNativeSock from 'vue-native-websocket';
Vue.use(
  VueNativeSock,
  process.env.NODE_ENV === "production"
    ? "wss://plan.cvur.com.tw/ws/backend/"
    : "ws://127.0.0.1:8000/ws/backend/",
  {
  connectManually: true,
  format: 'json',
  store: store,
  mutationPrefix: 'SOCKET_',
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
  passToStoreHandler: function (eventName, event) {
    if (!eventName.startsWith('SOCKET_')) {
      return;
    }
    let method = 'commit';
    let target = eventName.toUpperCase();
    let msg = event;
    if (this.format === 'json' && event.data) {
      msg = JSON.parse(event.data);
    }
    this.store[method]('websocket/' + target, msg);
  }
});

import VueExcelEditor from "vue-excel-editor";
Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);
Vue.use(VueExcelEditor);
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
// axios intercept for 401 http error , after login success redirect to previous page
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // store.dispatch("logout");
      // router.push({ path: "/pages/authentication/signin" }).catch(() => { });
      const currentRoute = router.currentRoute.fullPath;
      console.log("current router", currentRoute);
      // dispatch the logout action and redirect to the login page
      store.dispatch("logout");
      // set the current route as a query parameter to the login page
      // const loginPath = `${router.options.base}${router.options.routes[0].path}`;
      const loginPath = "/login";
      router
        .push({
          path: loginPath,
          query: { redirect: currentRoute },
        })
        .catch(() => {});
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    // console.log("main.js created");
    this.$store.dispatch('fetchInitialData')
  },
  render: (h) => h(App),
}).$mount("#app");
