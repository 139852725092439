import Vue from "vue";
import VueRouter from "vue-router";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
// import DashboardLayout2 from "../views/Layout/DashboardLayout2.vue";

// Dashboard pages
// const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Teams = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Teams.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );
const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const SignUpIllustration = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Illustration.vue"
  );
// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/NewProduct.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
  );
// CRM
const CRMDashboard = () => import("../views/Dashboard/CRMDashboard.vue");
const Home = () => import("@/views/Dashboard/Home.vue");
const CustomerTable = () => import("@/views/Pages/CRM/CustomerTable.vue");
const CustomerSearch = () => import("@/views/Pages/CRM/CustomerSearch.vue");
const CustomerImport = () => import("@/views/Pages/CRM/CustomerImport.vue");
const UserList = () => import("@/views/Pages/CRM/Admin/UserList.vue");
const LandTranscript = () => import("@/views/Pages/CRM/LandTranscript.vue");
const BuildingTranscript = () =>
  import("@/views/Pages/CRM/BuildingTranscript.vue");
const RoleView = () => import("@/views/Pages/Role/RoleView.vue");
const Templates = () => import("@/views/Pages/CRM/Admin/Templates.vue");
const BulletinList = () => import("@/views/Pages/CRM/Admin/BulletinList.vue");
const CRMWizard = () => import("@/views/Pages/CRM/CRMWizard.vue");
const Binding = () => import("@/views/Pages/CRM/Admin/Binding.vue");
const ImageExcel = () => import("@/views/Pages/CRM/Admin/ImageExcel.vue");
const CustomerGroup = () => import("@/views/Pages/CRM/Admin/CustomerGroup.vue");

// Development and Transcript
const DevelopmentZones = () => import("@/views/Pages/CRM/DevelopmentZone.vue");
const DevelopmentTask = () => import("@/views/Pages/CRM/DevelopmentTask.vue");
const TranscriptImport = () => import("@/views/Pages/CRM/TranscriptImport.vue");

// general
const SignInBasic = () => import("@/views/Pages/General/SignIn.vue");
const ForgetPasswordForm = () =>
  import("@/views/Pages/General/ForgetPasswordForm.vue");
const NewUser2 = () => import("@/views/Pages/General/NewUser.vue");
const UserSettings = () => import("@/views/Pages/General/UserSettings.vue");
const SignupStep = () => import("@/views/Pages/General/SignupStep.vue");
const AddUser = () => import("@/views/Pages/General/AddUser.vue");
const EditUser = () => import("@/views/Pages/General/EditUser.vue");
const ForgetPassword = () => import("@/views/Pages/General/ForgetPassword.vue");
const Verify = () => import("@/views/Pages/CRM/Verify.vue");
const Me = () => import("@/views/Pages/General/ChangePassword.vue");
const CaseSalesList = () => import("@/views/Pages/CRM/CaseSalesList.vue");
const CaseManagement = () => import("@/views/Pages/CRM/CaseManagement.vue");
const CaseDraft = () => import("@/views/Pages/CRM/CaseDraft.vue");
const Transcript = () => import("@/views/Pages/CRM/Widgets/Transcript.vue");
// DIY

const BuildingInfo = () => import("@/views/Pages/CRM/BuildingInfo.vue");
const CalendarView = () => import("@/views/Pages/DIY/CalendarView.vue");
const Recharge = () => import("@/views/Pages/DIY/Recharge.vue");
const RechargeSummary = () => import("@/views/Pages/DIY/RechargeSummary.vue");
const OrderOfShop = () => import("@/views/Pages/DIY/OrderOfShop.vue");
const OrderOfRecharge = () => import("@/views/Pages/DIY/OrderOfRecharge.vue");
const OrderOfAll = () => import("@/views/Pages/DIY/OrderOfAll.vue");
const OrderDone = () => import("@/views/Pages/DIY/OrderDone.vue");
const OrderAdmin = () => import("@/views/Pages/CRM/Admin/OrderList.vue");
const CalendarAdmin = () => import("@/views/Pages/DIY/Admin/Calendar.vue");
const ScheduleAdmin = () => import("@/views/Pages/DIY/Admin/ScheduleTable.vue");
const MemberSetting = () => import("@/views/Pages/DIY/MemberSetting.vue");

// tools
const LBKeyTool = () => import("@/views/Tools/LBKeyTool.vue");

Vue.use(VueRouter);

let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/teams",
      name: "Teams",
      component: Teams,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

let ecommercePages = {
  path: "/",
  component: ProfileLayout,
  name: "Ecommerce",
  children: [
    {
      path: "/pages/ecommerce/products/new-product",
      name: "NewProduct",
      component: NewProduct,
      meta: {
        groupName: "Ecommerce",
      },
    },
  ],
};

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/pages/authentication/signup/basic",
      name: "SignUpBasic",
      component: SignUpBasic,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "/pages/authentication/signup/cover",
      name: "SignUpCover",
      component: SignUpCover,
    },
  ],
};

let authIllustrationPages = {
  path: "/",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [
    {
      path: "/pages/authentication/signup/illustration",
      name: "SignUpIllustration",
      component: SignUpIllustration,
    },
  ],
};

// let toolPages = {
//   path: "/",
//   component: DashboardLayout,
//   name: "Tool Page",
//   children: [
//     {
//       path: "/pages/tool/alert",
//       name: "ToolAlert",
//       component: LBKeyTool,
//     },
//   ],
// };

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/Index",
    component: DashboardLayout,
    //component: DashboardLayout2,
    children: [
      // {
      //   path: "/dashboards",
      //   name: "Default",
      //   component: Dashboard,
      //   meta: {
      //     groupName: "Dashboards",
      //   },
      // },
      {
        path: "/dashboards",
        name: "Dashboard",
        component: CRMDashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/Index",
        name: "Index",
        component: Home,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/ecommerce/products/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/product-page",
        name: "ProductPage",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list",
        name: "OrderList",
        component: OrderList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/details",
        name: "OrderDetails",
        component: OrderDetails,
        meta: {
          groupName: "Ecommerce",
        },
      },
      // CRM
      {
        path: "/login",
        name: "SignIn",
        component: SignInBasic,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/authentication/signup",
        name: "SignUp",
        component: SignupStep,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/authentication/signin/me",
        name: "Me",
        component: Me,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/authentication/verify",
        name: "Verify",
        component: Verify,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/authentication/forget-password-form",
        name: "ForgetPasswordForm",
        component: ForgetPasswordForm,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/authentication/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        // path: "/pages/customer/table",
        path: "/customer-table",
        name: "CustomerTable",
        component: CustomerTable,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/customer-search",
        name: "CustomerSearch",
        component: CustomerSearch,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/customer-import",
        name: "CustomerImport",
        component: CustomerImport,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/user-list",
        name: "UserList",
        component: UserList,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/user-new",
        name: "NewUser2",
        component: NewUser2,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/user-setting",
        name: "Member",
        component: UserSettings,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/add-user",
        name: "AddUser",
        component: AddUser,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/edit-user",
        name: "EditUser",
        component: EditUser,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
        props: true,
      },
      {
        path: "/land-transcript",
        name: "LandTranscript",
        component: LandTranscript,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/building-transcript",
        name: "BuildingTranscript",
        component: BuildingTranscript,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/building-info",
        name: "BuildingInfo",
        component: BuildingInfo,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/case-management",
        name: "CaseManagement",
        component: CaseManagement,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/case-sales",
        name: "CaseSalesList",
        component: CaseSalesList,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/case-draft",
        name: "CaseDraft",
        component: CaseDraft,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/calendar-view",
        name: "CalendarView",
        component: CalendarView,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/order-done",
        name: "OrderDone",
        component: OrderDone,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/recharge",
        name: "Recharge",
        component: Recharge,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/recharge-summary",
        name: "Recharge Summary",
        component: RechargeSummary,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: "/pages/customer/tool",
        name: "ToolAlert",
        component: LBKeyTool,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/admin/role-view",
        name: "RoleView",
        component: RoleView,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/member-setting",
        name: "MemberSetting",
        component: MemberSetting,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/shop-order-list",
        name: "OrderOfShop",
        component: OrderOfShop,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/all-order-list",
        name: "OrderOfAll",
        component: OrderOfAll,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/recharge-order-list",
        name: "OrderOfRecharge",
        component: OrderOfRecharge,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
        props: {
          shop_type: 1,
        },
      },
      {
        path: "/pages/customer/admin-order-list",
        name: "OrderAdmin",
        component: OrderAdmin,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/admin-calendar",
        name: "CalendarAdmin",
        component: CalendarAdmin,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/customer/admin-schedule",
        name: "ScheduleAdmin",
        component: ScheduleAdmin,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/pages/admin/templates",
        name: "TemplatesAdmin",
        component: Templates,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/admin/bulletins",
        name: "BulletinList",
        component: BulletinList,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/crm-wizard",
        name: "CRMWizard",
        component: CRMWizard,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/binding-list",
        name: "Binding",
        component: Binding,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/image-excel",
        name: "ImageExcel",
        component: ImageExcel,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      {
        path: "/customer-group",
        name: "CustomerGroup",
        component: CustomerGroup,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      // DevelopmentZones
      {
        path: "/development-zones",
        name: "DevelopmentZones",
        component: DevelopmentZones,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
      // DevelopmentTasks
      {
        path: "/development-tasks",
        name: "DevelopmentTask",
        component: DevelopmentTask,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
        props: {
          zoneId: 1,
          lbtype:"L",
        },
      },
      // TranscriptImport
      {
        path: "/transcript-import",
        name: "TranscriptImport",
        component: TranscriptImport,
        meta: {
          groupName: "CRM",
          hideFooter: true,
        },
      },
    ],
  },
  pricingPage,
  profilePages,
  applicationPages,
  ecommercePages,
  userPages,
  accountPages,
  projectsPages,
  vrPages,
  authBasicPages,
  authCoverPages,
  authIllustrationPages,
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
