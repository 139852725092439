// store/modules/websocket.js
export default {
    namespaced: true,
    state: {
      isConnected: false,
      message: null,
      reconnectError: false
    },
    mutations: {
      SOCKET_ONOPEN(state) {
        state.isConnected = true;
      },
      SOCKET_ONCLOSE(state) {
        state.isConnected = false;
      },
      SOCKET_ONERROR(state) {
        state.reconnectError = true;
      },
      SOCKET_ONMESSAGE(state, message) {
        state.message = message;
      },
      SOCKET_RECONNECT(state) {
        state.isConnected = true;
      },
      SOCKET_RECONNECT_ERROR(state) {
        state.reconnectError = true;
      }
    }
  };